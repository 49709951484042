.Upload #header{
    width: 100%;
    text-align: center;  
    margin-top: 50px;
}

.Upload .Inputs{
    width: 50%;
}

.Upload .Uploads{
    width: 50%;

}

.upload-ui-options{
    text-align: center;
border: 1px solid black;
    padding: 5px;
    margin: 5px;
    margin-top: 12px;
    transition: 0.12s all ease-in-out;
    color: black;

    background-color: white;
    font-weight: bolder;
    width: 150px;
  
        
}
.upload-ui-options:hover{
    color: white;
    background-color: #1433A6;
    box-shadow: 0 0 0 0.2rem rgba(4, 124, 152,0.25);
    
}
#autofill-button-click-container:hover{
    
}
#reset-button-click-container:hover{
    border: 0.5px solid rgb(255, 24, 0);
    background-color: red;
    color: white;



}
.UploadFile label{
    border: 1px solid black;
    padding-top: 25px;
    padding-bottom: 50px;
    width: 38.2%;
    
}

.UploadFile label input{
    visibility: hidden;
}


.UploadFile .hover-me{
    transition: 0.13s all ease-in-out;

}

.UploadFile .hover-me:hover{
    transition: 0.13s all ease-in-out;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);    
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .Upload .Inputs{
     
        width: 100%;
    }
    
    .Upload .Uploads{
        width: 100%;
    
    }
    

}

@media screen and (min-width: 800px) {
    .Upload .Inputs{
        width: 50%;
    }
    
    .Upload .Uploads{
        width: 50%;
    
    }
    

}



