@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {

    #inputValid{
        height: 30px;
            
    }

}

@media screen and (min-width: 800px) {


}