.HeaderWithSearch{
    background-image: url('https://res.cloudinary.com/eduprojectsil/image/upload/e_art:hokusai/v1560758812/cloud_kg4voj_q21jhz.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: calc(100vh + 10px);
    width: 100%;
    color: black;
    
}

.HeaderWithSearch #react-needs-parent{
    width: 100%;
}

.HeaderWithSearch .fa-search{
    transition: 0.12s all ease-in-out;
}
.HeaderWithSearch .fa-search:hover{

    color: cyan;


}
.HeaderWithSearch h2{
    text-align: center;
}

.HeaderWithSearch form{
    width: 40%;
}
.HeaderWithSearch label input{
    margin-left: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .HeaderWithSearch form{
        width: 75%;
    }
    .HeaderWithSearch label{
        width: 100%;
    }
    .HeaderWithSearch label input{
       width: 100%;
     
    }

}

@media screen and (min-width: 800px) {
    .HeaderWithSearch form{
        width: 40%;
    }
    .HeaderWithSearch label{
        width: 100%;
    }

}