.ProgressBar{
    
    margin-bottom: 10px;
}


.progress-bar {
    height: 25px;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
  }
  .progress-bar::-moz-progress-bar
  {
      background: rgb(12, 118, 132)
  }
  .progress-bar::-webkit-progress-bar
  {
      background: transparent;
  }
  .progress-bar::-webkit-progress-value
  {
      background: rgb(12, 118, 132);
  }