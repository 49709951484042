.LibraryItem{
    justify-content: flex-start;
    color: black;
    text-align: center;
    height: 300px;
    border-radius: 6px;

    margin: 10px;

    transition: all 0.3s cubic-bezier(.25,.8,.25,1);    
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.LibraryItem:hover{
    transform: scale(1.03);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.LibraryItem #card-text-container{
    background-color: rgba(0,0,0,0.8);
    color: white;
    height: 100%;
    width: 100%;
    text-shadow: 1px 1px #000;

}

.LibraryItem #card-text-container #bold-resource{
    font-weight: bolder;
}

.LibraryItem #card-text-container h3{
    height: 38.2%;
}
.LibraryItem img{


}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .LibraryItem{
        width: 75%;
    }

}

@media screen and (min-width: 800px) {

    .LibraryItem{
        width: 29%;
    }
}