.RadioForm-Container{

  
}
.RadioForm{

  width: 100%;
}

.RadioForm div{margin-left: 15px;
margin-right: 15px;}

