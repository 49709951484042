.Library{
    width: 100%;
    margin-top: 50px;

}
.Library button{
    outline: none;
    transition: 0.13s all ease-in-out;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.Library button:hover{
    transform: scale(1.03);
}

.Library h2{

    width: 100%;
    padding-left: 20px;
}

.Library .Form-container{
 
    align-items: center;
    background-color: white;
    
  
 
}

.Library .Form-container div button{

    height: 35px;
    width: 35px;
    border-radius: 46%;
    border: 1px solid rgba(61, 138, 142, 1);
    background-color: #63A8AB;
    color: white;
    margin: 5px;
}

.Library .Library-container{
    width: 61.8%;
    margin-left: 38.2%;
    margin-top: 49px;
    
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {

    .Library{
        flex-direction: column;

    }

    .Library .Form-container{
        width: 100%;    
        height: initial;
        position: relative;
        align-items: center;
        font-size: 12px;
        
        
      
     
    }

    .Library .Library-container{
        width: 100%;
        margin-left: 0%;
        
    }
    
}

@media screen and (min-width: 800px) {
    .Library{
        flex-direction: row;
    }

    .Library .Form-container{
        height: 100vh;
        width: 38.2%;
        align-items: center;
        position: fixed;

        
        
      
     
    }

    .Library .Library-container{
        width: 61.8%;
        margin-left: 38.2%;
        
    }
    
}