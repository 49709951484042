#library-list{
   
    margin: 10px;

}


@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {

    #library-list{
        width: 100%;
    }
}

@media screen and (min-width: 800px) {

    #library-list{
        width: 100%;
    }
}  